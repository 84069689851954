import styled from 'styled-components';

interface ProdutoDestaqueProps {
  tipoCardImagem?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0px;
  background-color: #F7F7F7;
  overflow-x: hidden;
  height: auto;

  p {
    font-size: 2.5rem;
    font-weight: bold;
    font-style: italic;
    text-align: center;
  }

  @media screen and (max-width: 767px){
    p {
      font-size: 1.5rem;
    }
  }

`;

export const CarouselContainer = styled.div<ProdutoDestaqueProps>`
  display: flex;
  align-items: center;
  height: auto;
`;

