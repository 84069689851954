import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';

import RouterComponent from './components/RouterComponent';
import SEO from './components/SEO';

import { GlobalStyles } from './styles/GlobalStyles';

import Context, { ICarrinho, IConfig, IContext, IDadosLogin, IListaDesejo, IRodape } from './context/Context';
import ErrorPage from './pages/ErrorPage';

import api from './services/api';

(function gtmModule() {
  if (!import.meta.env.VITE_GTM_ID) return;

  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID
  };

  TagManager.initialize(tagManagerArgs);
})();

declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];

function App() {
  const [configs, setConfigs] = useState<IConfig[]>([]);
  const [rodape, setRodape] = useState<IRodape[]>([]);
  const [carrinho, setCarrinho] = useState<ICarrinho[]>([]);
  const [listaDesejos, setListaDesejos] = useState<IListaDesejo[]>([]);
  const [sideBarCarrinhoIsOpen, setSideBarCarrinhoIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [dadosLogin, setDadosLogin] = useState<IDadosLogin>({
    id: 0, endUsu: [], username: '',
    password: '', raz: '', ema: '',
  });

  const contextValues: IContext = {
    configs, setConfigs, rodape, setRodape,
    carrinho, setCarrinho, dadosLogin,
    setDadosLogin, listaDesejos, setListaDesejos,
    sideBarCarrinhoIsOpen, setSideBarCarrinhoIsOpen, isLoading,
    setIsLoading, error, setError
  };

  async function verificaUsuarioLogado() {
    try {
      const dadosLoginCookie = Cookies.get('@dadosLogin');

      if (dadosLoginCookie) {
        const dadosLogin = JSON.parse(dadosLoginCookie);
        if (!dadosLogin?.ema || !dadosLogin?.password) {
          throw Error('Credenciais inválidas.');
        }

        const response = await api.get(`/usuarios/loginProvisorio?email=${dadosLogin?.ema}&password=${dadosLogin?.password}`);

        if (response.status === 200) {
          if (response.data.length === 0) {
            throw Error('Usuário/Senha não encontrado.');
          }
          const dadLogin = response.data;

          setDadosLogin(response.data);
          Cookies.set('@dadosLogin', JSON.stringify(dadLogin), { expires: 7, domain: window.location.hostname });
        }
      }

    } catch (error: any) {
      Cookies.remove('@dadosLogin', { domain: window.location.hostname });
    }
  }

  useEffect(() => {
    verificaUsuarioLogado();
  }, []);

  //Nome da aba do navegador fica alternando entre nome da empresa e mensagem quando usuário está fora do site.
  useEffect(() => {
    let intervaloTitle: any;

    function handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        intervaloTitle = setInterval(() => {
          window.document.title = document.title === import.meta.env.VITE_TITLE ? 'Seus produtos te aguardam 😍' : import.meta.env.VITE_TITLE;
        }, 1000);
        return;
      }
      clearInterval(intervaloTitle);
      window.document.title = import.meta.env.VITE_TITLE;
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <Context.Provider value={contextValues}>
        <HelmetProvider>
          <SEO
            title={import.meta.env.VITE_TITLE}
            description={import.meta.env.VITE_DESCRIPTION}
            keywords={import.meta.env.VITE_KEYWORDS}
            imageUrl={import.meta.env.VITE_IMAGE_URL}
            pageUrl={import.meta.env.VITE_PAGE_URL}
          />
          <GlobalStyles />
          <ToastContainer
            style={{ zIndex: 999999999999 }}
            position='top-right'
            theme='colored'
          />
          {!error ?
            <RouterComponent /> :
            <ErrorPage error={error} />
          }
        </HelmetProvider>
      </Context.Provider>
    </>
  );
}

export default App;
