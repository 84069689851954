import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
  keywords: string;
  name?: string;
  type?: string;
  imageUrl?: string;
  pageUrl: string;
}

export default function SEO({ title, description, keywords, imageUrl, pageUrl }: SEOProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description || 'Sua Loja Virtual'} />
      <meta name='keywords' content={keywords || 'Ecommerce, Loja Virtual, Online'} />
      <meta name='copyright' content={title || 'T.I Fire Sistemas e Consultoria'} />
      <meta name='author' content={title || 'T.I Fire Sistemas e Consultoria'} />
      <meta name='country' content={'BRA'} />
      <meta name='geo.region' content='BR' />
      <meta name='geo.placename' content='Maringá' />
      <meta name='rating' content='general' />
      <meta name='language' content={'pt-BR'} />
      <meta httpEquiv='content-language' content='pt-BR' />
      <meta name='currency' content={'BRL'} />
      <meta name='robots' content={'index, follow'} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageUrl || ''} />
      <meta property='og:url' content={pageUrl || ''} />
      <meta property='og:type' content={'website'} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageUrl || ''} />
      <meta name='revised' content='2024-02-26' />
    </Helmet>
  );
}
