import React, { useState, useEffect, useContext } from 'react';
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Accordion from '../../components/Accordion';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import {
  Container, Banner, TitleDiv, ProdutosDiv,
  FiltrosDiv, CardsDiv, ActivityIndicator, NotFoundDiv, CardsInfiniteScrollDiv
} from './styles';
import Context, { IContext, IListaDesejo } from '../../context/Context';
import SelectInput from '../../components/SelectInput';

import NotFoundSvg from '../../assets/images/not_found.svg';
import { formatCurrency } from '../../utils/formatCurrency';
import api from '../../services/api';
import semFoto from '../../assets/images/sem-foto.jpg';

interface IProdutoCard {
  linkFot: string;
  mer: string;
  codbar: string;
  valVenMin: number;
  esgSit?: boolean;
}

export default function ProdutoListagem() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pesquisa } = useParams();
  const { configs, dadosLogin, setListaDesejos }: IContext = useContext(Context);
  const termoPesquisa = pesquisa?.split('=') ?? [];

  const [produtos, setProdutos] = useState<IProdutoCard[]>([]);
  const [temMais, setTemMais] = useState<boolean>(true);
  const [pagina, setPagina] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //filtros
  const [ordem, setOrdem] = useState<string | undefined>();
  const [filtroCores, setFiltroCores] = useState<any>([]);
  const [filtroTamanhos, setFiltroTamanhos] = useState<any>([]);
  const [filtroValVen, setFiltroValVen] = useState<any>({ min: 0, max: 0 });
  const [filtroValVenSelecionado, setFiltroValVenSelecionado] = useState<any>({ min: '', max: '', alterado: false });
  const [filtroCoresSelecionadas, setFiltroCoresSelecionadas] = useState<string[] | undefined>();
  const [filtroTamanhosSelecionados, setFiltroTamanhosSelecionados] = useState<string[] | undefined>();

  //config
  const [logoURI, setLogoURI] = useState<string>('');

  async function getProdutos(novaPesquisa?: boolean) {
    try {
      if (!termoPesquisa || termoPesquisa.length < 2) {
        throw Error('URL inválida');
      }

      if (novaPesquisa) {
        setPagina(undefined);
        setTemMais(true);
        setFiltroValVenSelecionado((prev: any) => ({ ...prev, alterado: false }));
      }

      if (termoPesquisa[0] === 'listaDesejos') {
        getListaDesejo();
        return;
      }

      if (!temMais && !novaPesquisa) return;

      setIsLoading(true);

      const params = {
        page: novaPesquisa ? 0 : pagina,
        codtabpre: 0,
        size: 8,
        sort: ordem ?? 'mer,asc',
        // cores: filtroCoresSelecionadas.join(','),
        // tamanhos: filtroTamanhosSelecionados.join(','),
        // valvenmin: filtroValVenSelecionado.min,
        // valvenmax: filtroValVenSelecionado.max,
        ...(filtroValVenSelecionado.alterado && { valvenmin: filtroValVenSelecionado.min, valvenmax: filtroValVenSelecionado.max }),
        ...((Array.isArray(filtroCoresSelecionadas) && filtroCoresSelecionadas.length > 0) && { cores: filtroCoresSelecionadas.join(',') }),
        ...((Array.isArray(filtroTamanhosSelecionados) && filtroTamanhosSelecionados.length > 0) && { tamanhos: filtroTamanhosSelecionados.join(',') }),
        ...(termoPesquisa[0] === 'secMer' && { secmer: termoPesquisa[1]?.replaceAll(' - ', '/') }),
        ...(termoPesquisa[0] === 'itemMenu' && { itemClicado: termoPesquisa[1]?.replaceAll(':', '=') }),
        ...(termoPesquisa[0] === 'pesquisa' && { pesquisa: termoPesquisa[1]?.replaceAll(' - ', '/') }),
        ...(termoPesquisa[0] === 'subSecMer' && { subsecmer: termoPesquisa[1]?.replaceAll(' - ', '/') }),
      };

      if (novaPesquisa) {
        delete params.valvenmin;
        delete params.valvenmax;
      }

      const responseMercador = await api.get('/mercador/listarProdutosCard', {
        params: params
      });

      if (responseMercador.status === 200) {

        if (responseMercador.data.content.length === 0) {
          setTemMais(false);
          return;
        }

        const newProdutos: IProdutoCard[] = responseMercador.data.content.map((produto: any) => {
          return {
            linkFot: produto.linkFot ? 'https://' + produto.linkFot : semFoto,
            mer: produto.mer,
            codbar: produto.codBar,
            valVenMin: produto.valVenMin,
            esgSit: Boolean(produto.esgSit)
          };
        });
        setProdutos([...produtos, ...newProdutos]);
      }

    } catch (error: any) {
      toast.error('Falha ao buscar produtos. ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getListaDesejo() {
    try {

      if (dadosLogin.id === 0) {
        toast.warning('Faça login para acessar sua lista de desejos');
        navigate('/login');
        return;
      }

      const response = await api.get(`/itelisdes/listarPorUsuario?id=${dadosLogin.id}`);

      if (response.status === 200) {
        if (response.data === 'Produto não encontrado') {
          return;
        }

        const listaDeDesejosNuvem: IListaDesejo[] = response.data.content.map((iteLisDes: any) => {
          return {
            cod: iteLisDes.codIteLisDes,
            codmer: iteLisDes.cod,
            linkFot: iteLisDes.linkFot ? 'https://' + iteLisDes.linkFot : semFoto,
            mer: iteLisDes.mer,
            codbar: iteLisDes.codBar,
            valVenMin: iteLisDes.valVenMin
          };
        });

        if (listaDeDesejosNuvem.length > 0) {
          setListaDesejos(listaDeDesejosNuvem);
          setProdutos(listaDeDesejosNuvem);
          setTemMais(false);
          return;
        }

      }

    } catch (error: any) {
      console.log('Falha ao buscar lista de desejos. ' + error.message);
    }
  }

  async function getFiltros() {
    try {

      if (termoPesquisa[0] === 'listaDesejos') return;

      const params = {
        page: 0,
        codtabpre: 0,
        ...(termoPesquisa[0] === 'secMer' && { secmer: termoPesquisa[1]?.replaceAll(' - ', '/') }),
        ...(termoPesquisa[0] === 'itemMenu' && { itemClicado: termoPesquisa[1]?.replaceAll(':', '=') }),
        ...(termoPesquisa[0] === 'pesquisa' && { pesquisa: termoPesquisa[1]?.replaceAll(' - ', '/') }),
        ...(termoPesquisa[0] === 'subSecMer' && { subsecmer: termoPesquisa[1]?.replaceAll(' - ', '/') }),
      };

      const response = await api.get('/mercador/listarItensFiltros', {
        params: params
      });

      if (response?.status === 200 && response.data !== 'Nenhum produto a exibir') {
        const {
          cores, tamanhos,
          valVenMin, valVenMax
        } = response.data;

        setFiltroCores(cores.map((cor: any) => { return { cod: cor.cod, texto: cor.padmer }; }));
        setFiltroTamanhos(tamanhos.map((tamanho: string) => { return { cod: tamanho, texto: tamanho }; }));
        setFiltroValVen({ min: valVenMin, max: valVenMax });
        setFiltroValVenSelecionado({ min: valVenMin, max: valVenMax });
      }

    } catch (error: any) {
      toast.error('Falha ao buscar filtro. ' + error.message);
    } finally {
      if (filtroCoresSelecionadas) {
        setFiltroCoresSelecionadas(undefined);
      }
      if (filtroTamanhosSelecionados) {
        setFiltroTamanhosSelecionados(undefined);
      }
    }
  }

  function onScroll() {
    setPagina((prev: number | undefined) => prev ? prev + 1 : 1);
  }

  function scrollToTop() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }

  function novaPesquisa() {
    scrollToTop();

    produtos.length = 0;

    // setTemMais(true);
    getProdutos(true);
  }

  function handleMultiSliderInput(e: ChangeResult) {
    if (JSON.stringify({ min: filtroValVenSelecionado.min, max: filtroValVenSelecionado.max }) === JSON.stringify({ min: e.minValue, max: e.maxValue })) return;

    produtos.length = 0;

    setFiltroValVenSelecionado({ min: e.minValue, max: e.maxValue, alterado: true });

    getProdutos();
  }

  useEffect(() => {
    if (ordem !== undefined || filtroTamanhosSelecionados !== undefined || filtroCoresSelecionadas !== undefined) {
      novaPesquisa();
    }
  }, [ordem, filtroTamanhosSelecionados, filtroCoresSelecionadas]);

  useEffect(() => {
    if (pagina !== undefined) {
      getProdutos();
    }
  }, [pagina]);

  useEffect(() => {
    novaPesquisa();
    getFiltros();
  }, [pesquisa]);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: uri }] = configs.filter((config: any) => config.gru === 'logo');

      setLogoURI('https://' + uri);
    }
  }, [configs]);

  return (
    <Container>
      {location.state?.linimaban && location.state?.linimaban !== '' && <Banner src={'https://' + location.state.linimaban} alt='Banner de seção' />}
      <TitleDiv>
        {/* <span>{location?.state?.caminho ?? ''}</span> */}
        <span></span>

        {termoPesquisa.length > 1 ? termoPesquisa[0] === 'pesquisa' ?
          <b>{`Palavra - chave: ${termoPesquisa[1].replaceAll(' - ', '/')}`}</b> :
          <b>{`${location?.state?.caminho ?? ''}`}</b>
          : ''}
        {termoPesquisa[0] === 'listaDesejos' && <b>Lista de Desejos</b>}
        {
          termoPesquisa[0] !== 'listaDesejos' ?
            <SelectInput
              value={ordem ?? 'mer,asc'}
              onChange={setOrdem}
              options={[
                { value: 'mer,asc', text: 'De A a Z' },
                { value: 'mer,desc', text: 'De Z a A' },
                { value: 'valvenmin,asc', text: 'Menor Preço' },
                { value: 'valvenmin,desc', text: 'Maior Preço' },
              ]}
            /> :
            <div />
        }
      </TitleDiv>
      <ProdutosDiv>
        <FiltrosDiv>
          {termoPesquisa[0] !== 'listaDesejos' &&
            <>
              <span>
                Filtros
              </span>
              {filtroCores.length > 0 &&
                <Accordion
                  titulo={'Cores'}
                  texto={'Cores'}
                  checkboxInputArray={filtroCores}
                  checkboxInputValuesArray={filtroCoresSelecionadas ?? []}
                  setFiltro={setFiltroCoresSelecionadas}
                />
              }
              {filtroTamanhos.length > 0 &&
                <Accordion
                  titulo={'Tamanho'}
                  texto={'Tamanhos'}
                  checkboxInputArray={filtroTamanhos}
                  checkboxInputValuesArray={filtroTamanhosSelecionados ?? []}
                  setFiltro={setFiltroTamanhosSelecionados}
                />
              }
              {filtroValVen.max !== 0 &&
                <>
                  <br />
                  <p>Faixa de preço</p>
                  <MultiRangeSlider
                    min={filtroValVen.min}
                    max={filtroValVen.max}
                    step={1}
                    minValue={filtroValVenSelecionado.min}
                    maxValue={filtroValVenSelecionado.max}
                    onChange={handleMultiSliderInput}
                    label={false}
                    ruler={false}
                    style={{ border: 'none', boxShadow: 'none', padding: '15px 10px' }}
                    barInnerColor='#1D1D1D'
                    thumbLeftColor='#fff'
                    thumbRightColor='#fff'
                  />
                  <strong>De {formatCurrency(filtroValVenSelecionado.min ? filtroValVenSelecionado.min : filtroValVen.min)} a {formatCurrency(filtroValVenSelecionado.max ? filtroValVenSelecionado.max : filtroValVen.max)}</strong>
                </>
              }
            </>
          }
        </FiltrosDiv>
        <CardsDiv>
          {produtos.length > 0 ?
            <CardsInfiniteScrollDiv
              dataLength={produtos.length}
              next={onScroll}
              hasMore={temMais}
              endMessage={
                <p style={{ textAlign: 'center', margin: 'auto' }}>
                  <b>Fim da lista 😊</b>
                  <br />
                  <span
                    onClick={() => scrollToTop()}
                    style={{ cursor: 'pointer', color: 'blue' }}
                  >Voltar ao início</span>
                </p>
              }
              style={{ overflow: 'hidden' }}
            >
              {produtos.map((produto: any, index: number) => {
                return (
                  <div key={index} style={{ margin: 10 }}>
                    <Card
                      imageSrc={produto.linkFot}
                      nome={produto.mer}
                      codbar={produto.codbar}
                      preço={produto.valVenMin}
                      esgotado={produto.esgSit}
                    />
                  </div>);
              })}
            </CardsInfiniteScrollDiv>
            :
            !isLoading &&
            <NotFoundDiv>
              <img src={NotFoundSvg} alt='Imagem de nenhum produto encontrado' />
              <b>Não encontramos nenhum resultado para sua busca. Tente palavras menos específicas ou palavras-chave e filtros diferentes.</b>
            </NotFoundDiv>
          }
        </CardsDiv>
      </ProdutosDiv>
      {isLoading &&
        <ActivityIndicator src={logoURI} alt='Logo da Empresa' />
      }
      <Footer />
    </Container>
  );
}
