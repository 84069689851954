import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import {
  Anchor, Button, Container, GhostButton, Input, LeftOverlayPanel, Overlay,
  OverlayContainer, Paragraph, RightOverlayPanel, LoginContainer,
  CadastroContainer, Title, Form, LoginDiv, Logo, RedefinirPasswordContainer,
  LoginDivOption,
  CadastroContainerPersonalizado,
  LoginContainerPersonalizado
} from './styles';

import Context, { IContext } from '../../context/Context';

import api from '../../services/api';
import { cnpjMask, cpfMask, validaCpfCnpj } from '../../utils/ValidaCpfCnpj';
import { Carousel } from 'react-responsive-carousel';
import useWindowDimensions from '../../utils/WindowDimensions';

interface LoginProps {
  username: string;
  password: string;
  raz?: string;
  fan?: string;
  cgc?: string;
  ema: string;
  tipusu: 'comum';
}

export default function Login() {
  const navigate = useNavigate();
  const { form } = useParams();
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;

  const { configs, setDadosLogin }: IContext = useContext(Context);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [novoUsuario, setNovoUsuario] = useState<LoginProps>({ username: '', ema: '', password: '', raz: '', fan: '', cgc: '', tipusu: 'comum' });
  const [indexCarousel, setIndexCarousel] = useState<number>(
    form === 'entrar' ? 1 : form === 'redefinir' ? 2 : form === 'cadastrar' ? 3 : 0
  );

  const [randomCadastroFrase, setRandomCadastroFrase] = useState('');
  const [randomLoginFrase, setRandomLoginFrase] = useState('');

  //config
  const [logoURI, setLogoURI] = useState<string>('');
  const [NecCadAutMosPro, setNecCadAutMosPro] = useState<boolean>(false);
  const [usaTelLogPer, setUsaTelLogPer] = useState<boolean>(false);

  const frases = [
    'Sentiu minha falta né? 🥰',
    'Bom te ver por aqui 😜',
    'Tudo na paz? ✌',
    'Pode dar uma espiada nas novidades, eu deixo 😜',
    'Corre que tem promoção te esperando ✨',
    'Achou o que estava procurando? 👀',
    'É um prazer ter você conosco 💖',
    'Estava te esperando 👀',
    'Que tal uma dose de felicidade hoje? 😃',
    'Você é sempre bem-vindo(a) por aqui! 🤗',
    'Prepare-se para uma experiência incrível! 🌟',
    'Vamos começar com o pé direito? 👣',
    'Seja bem-vindo(a) de volta! 🎉',
    'Está pronto(a) para se surpreender? 🤩',
    'Nós sentimos sua falta! ❤️',
    'Pronto(a) para explorar novidades? 🚀',
  ];

  async function getLogin({ ema, password }: LoginProps) {
    try {
      if (!ema || !password) {
        toast.warning('Credenciais inválidas. Verique os campos digitados.');
        return;
      }

      const response = await api.get(`/usuarios/loginProvisorio?email=${ema}&password=${password}`);

      if (response.status === 200) {
        if (response.data.length === 0) {
          throw Error('Usuário/Senha não encontrado.');
        }
        const dadLogin = response.data;

        setDadosLogin(response.data);
        Cookies.set('@dadosLogin', JSON.stringify(dadLogin), { expires: 7, domain: window.location.hostname });
        if (password.startsWith('alt1@@')) {
          toast.success('Feliz em ter você de volta 🤩 Altere já a senha da sua conta no painel de usuário.');
          navigate('/painelDeUsuario/alterarSenha');
          return;
        }
        toast.success(`Bem-vindo ${dadLogin.raz ?? ''}!! Fique a vontade 😉`);
        navigate('/');
      }

    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error('Usuario e/ou senha inválidos.');
        return;
      }
      toast.error('Falha no login. ' + error.message);
    }
  }

  async function postNovoCadastro(novoCadastro: LoginProps) {
    try {
      if (!novoCadastro.ema || !novoCadastro.password || !novoCadastro.raz) {
        toast.warning('Dados inválidos. Verique os campos digitados.');
        return;
      }
      if (NecCadAutMosPro && !validaCpfCnpj(novoCadastro.cgc ?? '')) {
        toast.warning('CPF/CNPJ inválido.');
        return;
      }

      const response = await api.post('/usuarios/salvar', novoCadastro, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response?.status === 201) {
        const dadLogin = response.data;

        //api não estava salvando automatico como autorizado a ver preço
        if (api.defaults.baseURL === 'https://jaquelinebueano-api-f2468213c8d5.herokuapp.com/api') {
          dadLogin.autverprosit = 1;
        }

        setDadosLogin(response.data);
        Cookies.set('@dadosLogin', JSON.stringify(dadLogin), { expires: 7, domain: window.location.hostname });
        toast.success('Usuário cadastrado com sucesso');
        navigate('/');
      }

    } catch (error: any) {
      if (error.response.status === 409) {
        toast.error('Usuário já existente.');
        return;
      }

      if (error.response.data?.error) {
        toast.error('Falha ao cadastrar novo usuário. ' + error.response.data.error);
        return;
      }

      toast.error('Falha ao cadastrar novo usuário. ' + error.message);
    }
  }

  async function postRedefinirPassword(email: string) {
    try {
      if (!email) {
        toast.warning('Email inválido');
        return;
      }

      const response = await api.post(`/usuarios/resetPassword?email=${email}`);

      if (response.status === 200) {
        toast.success(response.data);
      }

    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data);
        return;
      }

      toast.error('Falha ao solicitar redefinição de senha. ' + error.message);
    }
  }

  const slides = [
    {
      component: (
        <LoginDiv
          width='90%'
          display='flex'
        >
          <LoginDivOption>
            <Title>Já Sou Cadastrado</Title>
            <Paragraph>Caso Já Tenha Cadastro, Clique em Acessar Conta.</Paragraph>
            <Button
              onClick={() => navigate('/login/entrar')}
            >
              Acessar conta
            </Button>
          </LoginDivOption>
          <hr />
          <LoginDivOption>
            <Title>Está é a Minha Primeira Compra</Title>
            <Paragraph>O Cadastro em Nossa Loja é Simples e Rápido</Paragraph>
            <Button
              onClick={() => navigate('/login/cadastrar')}
            >
              Cadastrar-se
            </Button>
          </LoginDivOption>
        </LoginDiv>
      )
    },
    {
      component: (
        <LoginDiv
          width={isMobile ? '90%' : '40%'}
        >
          <LoginContainerPersonalizado>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                getLogin({ ema: email, username: email, password: password, tipusu: 'comum' });
              }}
            >
              <Title>Vamos lá</Title>
              <Input type='email' placeholder='Email' value={email} required autoComplete='email' usaTelLogPer
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
                }
              />
              <Input type='password' placeholder='Senha' value={password} required usaTelLogPer
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
                }
              />
              <Anchor
                onClick={() => navigate('/login/redefinir')}
              >
                Esqueceu sua senha?
              </Anchor>
              <Button>Acessar</Button>
            </Form>
          </LoginContainerPersonalizado>
        </LoginDiv>
      )
    },
    {
      component: (
        <RedefinirPasswordContainer>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              postRedefinirPassword(email);
            }}
          >
            <Title>Recuperação de conta</Title>
            <Input type='email' placeholder='Digite seu email' value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
            <Button>Enviar email</Button>
            <Anchor
              onClick={() => navigate('/login')}
            >
              Acessar conta
            </Anchor>
          </Form>
        </RedefinirPasswordContainer>
      )
    },
    {
      component: (
        <LoginDiv
          width={isMobile ? '90%' : '40%'}
        >
          <CadastroContainerPersonalizado>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                postNovoCadastro(novoUsuario);
              }}
            >
              <Title>Criar Conta</Title>
              {NecCadAutMosPro &&
                <Input
                  usaTelLogPer
                  type='text'
                  placeholder={'CPF/CNPJ'}
                  value={novoUsuario.cgc}
                  required
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 14) {
                        setNovoUsuario(prev => ({ ...prev, cgc: cnpjMask(e.target.value) }));
                        return;
                      }
                      setNovoUsuario(prev => ({ ...prev, cgc: cpfMask(e.target.value) }));
                    }}
                />
              }
              <Input type='text' required placeholder={NecCadAutMosPro ? 'Nome Completo / Razão Social' : 'Nome Completo'} usaTelLogPer
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, fan: e.target.value, raz: e.target.value }))
                }
              />
              <Input type='email' placeholder='Email' required autoComplete='email' usaTelLogPer
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, username: e.target.value, ema: e.target.value }))
                }
              />
              <Input type='password' placeholder='Senha' required usaTelLogPer
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, password: e.target.value }))
                }
              />
              <Button>Cadastrar</Button>
            </Form>
          </CadastroContainerPersonalizado>
        </LoginDiv>
      )
    }
  ];

  useEffect(() => {
    setRandomCadastroFrase(frases[(Math.random() * ((frases.length - 1) - 0 + 1)) << 0]);
    setRandomLoginFrase(frases[(Math.random() * ((frases.length - 1) - 0 + 1)) << 0]);
  }, []);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: uri }] = configs.filter((config: any) => config.gru === 'logo');
      const [{ val: CadAutMosPro }] = configs.filter((config: any) => config.con === 'NecCadAutMosPro');
      const [{ val: UsaTelLogPer }] = configs.filter((config: any) => config.con === 'UsaTelLogPer');

      setLogoURI('https://' + uri);
      setNecCadAutMosPro(Boolean(+CadAutMosPro));
      setUsaTelLogPer(Boolean(+UsaTelLogPer));
    }
  }, [configs]);

  useEffect(() => {
    if (usaTelLogPer) {
      if (form === 'entrar') {
        setIndexCarousel(1);
        return;
      }
      if (form === 'redefinir') {
        setIndexCarousel(2);
        return;
      }
      if (form === 'cadastrar') {
        setIndexCarousel(3);
        return;
      }

      setIndexCarousel(0);
    }

  }, [form]);

  return (
    <Container>
      {usaTelLogPer ?
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          swipeable={false}
          emulateTouch={true}
          selectedItem={indexCarousel}
          onChange={setIndexCarousel}
          className='carousel'
        >
          {slides.map((slide, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#fff' }}>
              {slide.component}
            </div>
          ))}
        </Carousel>
        :
        <LoginDiv>
          {form !== 'redefinir' ?
            <>
              <CadastroContainer login={!form || form === 'entrar'}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postNovoCadastro(novoUsuario);
                  }}
                >
                  <Title>Criar Conta</Title>
                  {NecCadAutMosPro &&
                    <Input
                      type='text'
                      placeholder={'CPF/CNPJ'}
                      value={novoUsuario.cgc}
                      required
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > 14) {
                            setNovoUsuario(prev => ({ ...prev, cgc: cnpjMask(e.target.value) }));
                            return;
                          }
                          setNovoUsuario(prev => ({ ...prev, cgc: cpfMask(e.target.value) }));
                        }}
                    />
                  }
                  <Input type='text' required placeholder={NecCadAutMosPro ? 'Nome Completo / Razão Social' : 'Nome Completo'}
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, fan: e.target.value, raz: e.target.value }))
                    }
                  />
                  <Input type='email' placeholder='Email' required autoComplete='email'
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, username: e.target.value, ema: e.target.value }))
                    }
                  />
                  <Input type='password' placeholder='Senha' required
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setNovoUsuario(prev => ({ ...prev, password: e.target.value }))
                    }
                  />
                  <Button>Cadastrar</Button>
                </Form>
              </CadastroContainer>
              <LoginContainer login={!form || form === 'entrar'}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getLogin({ ema: email, username: email, password: password, tipusu: 'comum' });
                  }}
                >
                  <Title>Vamos lá</Title>
                  <Input type='email' placeholder='Email' value={email} required autoComplete='email'
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
                    }
                  />
                  <Input type='password' placeholder='Senha' value={password} required
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
                    }
                  />
                  <Anchor onClick={() => navigate('/login/redefinir')}>Esqueceu sua senha?</Anchor>
                  <Button>Acessar</Button>
                </Form>
              </LoginContainer>

              <OverlayContainer login={!form || form === 'entrar'}>
                <Overlay login={!form || form === 'entrar'}>
                  <LeftOverlayPanel login={!form || form === 'entrar'}>
                    <Title>Seja Bem-vindo!</Title>
                    <Paragraph>
                      {randomCadastroFrase}
                    </Paragraph>
                    <GhostButton onClick={() => navigate('/login')}>
                      Já tenho cadastro
                    </GhostButton>
                  </LeftOverlayPanel>

                  <RightOverlayPanel login={!form || form === 'entrar'}>
                    <Title>Bem-vindo de Volta!</Title>
                    <Paragraph>
                      {randomLoginFrase}
                    </Paragraph>
                    <GhostButton onClick={() => navigate('/login/cadastro')}>
                      Não tenho Conta
                    </GhostButton>
                  </RightOverlayPanel>
                </Overlay>
              </OverlayContainer>
            </> :
            <RedefinirPasswordContainer>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  postRedefinirPassword(email);
                }}
              >
                <Title>Recuperação de conta</Title>
                <Input type='email' placeholder='Digite seu email' value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                <Button>Enviar email</Button>
                <Anchor onClick={() => navigate('/login')}>Acessar conta</Anchor>
              </Form>
            </RedefinirPasswordContainer>
          }
        </LoginDiv>}
      {logoURI && <Logo src={logoURI} alt='Logo da Empresa' onClick={() => navigate('/')} />}
    </Container>
  );
}
